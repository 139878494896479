import React, {ReactNode} from "react";
import {createBrowserRouter, Outlet, Navigate} from "react-router-dom";
import {BranchListPage, GroupListPage, LoginPage, MainPage, EmployeesPage, ApprovalCreatePage} from "./Pages";
import Root from "./Pages/Root";
import DocumentsPage from "./Pages/Approval";
import OperateListPage from "./Pages/Operate";
import OperateCreatePage from "./Pages/Operate/Create";
import AttendanceCreatePage from "./Pages/Operate/Attendance";
import DefaultPage from "./Pages/Operate/Default";
import DataPage from "./Pages/Operate/Data";
import MonthlyListPage from "./Pages/Monthly";
import MonthlyCreatePage from "./Pages/Monthly/Create";
import EnviromentPage from "./Pages/System/Environment";
import AlarmListPage from "./Pages/MyPage/Alarm";
import BoardListPage from "./Pages/Board/List";
import PrintPage from "./Pages/Print/Print";
import MyInfoPage from "./Pages/MyPage/Info";
import MessageListPage from "./Pages/MyPage/Message";


const router = createBrowserRouter([
    {
        path: "/",
        label: "홈",
        visibleSideMenu: false,
        element: <Root/>,
        // loader: async ({}) => {
        //     return Promise.resolve()
        // },
        children: [
            {
                path: "Main",
                element: <MainPage/>,
                label: "메인",
                visibleSideMenu: false,
            },
            {
                path: "Branch",
                label: "지점관리",
                visibleSideMenu: true,
                children: [
                    {
                        path: "Group",
                        element: <GroupListPage/>,
                        label: "그룹목록",
                        visibleSideMenu: true,
                        branchType: "group",
                        children: [
                            {
                                path: ":id",
                                element: null,
                                label: "그룹상세",
                                visibleSideMenu: false,
                            },
                            {
                                path: "Create",
                                element: <MainPage/>,
                                label: "그룹생성",
                                visibleSideMenu: false,
                            }
                        ]
                    },
                    {
                        path: "Manage",
                        element: <BranchListPage/>,
                        label: "지점목록",
                        visibleSideMenu: true,
                        branchType: "group",
                        children: [
                            {
                                path: ":id",
                                element: null,
                                label: "지점상세",
                                visibleSideMenu: false,
                            },
                            {
                                path: "create",
                                element: null,
                                label: "지점생성",
                                visibleSideMenu: false,
                            }
                        ]
                    },
                ]
            },

            {
                path: "Employee",
                label: "직원관리",
                visibleSideMenu: true,
                children: [
                    {
                        path: "Manage",
                        element: <EmployeesPage/>,
                        label: "직원목록",
                        visibleSideMenu: true,
                        children: [
                            {
                                path: ":id",
                                element: <MainPage/>,
                                label: "직원상세",
                                visibleSideMenu: false,
                            },
                            {
                                path: "Create",
                                element: <MainPage/>,
                                label: "직원등록",
                                visibleSideMenu: false,
                            }
                        ]
                    },
                ]
            },
            {
                path: "Approval",
                label: "전자결재",
                visibleSideMenu: true,
                children: [
                    {
                        path: "Remittance",
                        element: <ApprovalCreatePage/>,
                        label: "전자결재 작성",
                        visibleSideMenu: true,
                        branchType: "branch"
                    },
                    {
                        path: "Expenses",
                        element: <ApprovalCreatePage/>,
                        label: "경비지출 작성",
                        branchType: "branch",
                        visibleSideMenu: false,
                    },
                    {
                        path: "Draft",
                        element: <ApprovalCreatePage/>,
                        label: "기안서 작성",
                        branchType: "branch",
                        visibleSideMenu: false,
                    },
                    {
                        path: "Official",
                        element: <ApprovalCreatePage/>,
                        label: "공문 작성",
                        branchType: "branch",
                        visibleSideMenu: false,
                    },
                    {
                        path: "Manage",
                        element: <DocumentsPage/>,
                        label: "결재목록",
                        visibleSideMenu: true,
                        children: [
                            {
                                path: ":id",
                                element: <MainPage/>,
                                label: "결재상세",
                                visibleSideMenu: false,
                            },
                        ]
                    },
                ]
            },

            {
                path: "Operate",
                label: "운영관리",
                visibleSideMenu: true,
                children: [
                    {
                        path: "Manage",
                        // element: null,
                        element: <OperateListPage/>,
                        label: "운영현황",
                        visibleSideMenu: true,
                        children: []
                    },
                    {
                        path: "Create",
                        element: <OperateCreatePage/>,
                        label: "운영일지 등록",
                        visibleSideMenu: true,
                        children: [],
                        branchType: "branch"
                    },
                    {
                        path: "Attendance",
                        element: <AttendanceCreatePage/>,
                        label: "근태등록",
                        visibleSideMenu: true,
                        children: [],
                        branchType: "branch"
                    },
                    {
                        path: "Default",
                        element: <DefaultPage/>,
                        label: "기본값설정",
                        visibleSideMenu: true,
                        children: [],
                        branchType: "branch"
                    },
                    {
                        path: "Data",
                        element: <DataPage/>,
                        label: "자료조회",
                        visibleSideMenu: true,
                        children: [],
                        branchType: "branch"
                    },
                ]
            },

            {
                path: "Monthly",
                label: "월별결산",
                visibleSideMenu: true,
                children: [
                    {
                        path: "Read",
                        element: <MonthlyListPage/>,
                        label: "결산확인",
                        visibleSideMenu: true,
                        children: []
                    },
                    {
                        path: "Create",
                        element: <MonthlyCreatePage/>,
                        label: "신규작성",
                        visibleSideMenu: true,
                        children: []
                    },
                ]
            },

            {
                path: "Board",
                label: "게시판",
                visibleSideMenu: true,
                children: [
                    {
                        path: "Notice",
                        element: <BoardListPage/>,
                        label: "공지사항",
                        visibleSideMenu: true,
                        children: [
                            {
                                path: ":id",
                                element: null,
                                label: "공지상세",
                                visibleSideMenu: false,
                                children: [
                                    {
                                        path: "Update",
                                        element: <MainPage/>,
                                        label: "공지수정",
                                        visibleSideMenu: false,
                                    },
                                ]
                            },

                            {
                                path: "Create",
                                element: <MainPage/>,
                                label: "공지등록",
                                visibleSideMenu: false,
                            },
                        ]
                    },
                    {
                        path: "Document",
                        element:  <BoardListPage/>,
                        label: "자료실",
                        visibleSideMenu: true,
                        children: [
                            {
                                path: ":id",
                                label: "자료실상세",
                                visibleSideMenu: false,
                                children: [
                                    {
                                        path: "Update",
                                        label: "자료실수정",
                                        visibleSideMenu: false,
                                    },
                                ]
                            },

                            {
                                path: "Create",
                                label: "자료실등록",
                                visibleSideMenu: false,
                            },
                        ]
                    },
                    {
                        path: "Carte",
                        element:  <BoardListPage/>,
                        label: "식단표",
                        visibleSideMenu: true,
                        children: [
                            {
                                path: ":id",
                                label: "식단표상세",
                                visibleSideMenu: false,
                                children: [
                                    {
                                        path: "Update",
                                        label: "식단표수정",
                                        visibleSideMenu: false,
                                    },
                                ]
                            },

                            {
                                path: "Create",
                                label: "식단표등록",
                                visibleSideMenu: false,
                            },
                        ]
                    },
                ]
            },

            {
                path: "System",
                label: "시스템 관리",
                visibleSideMenu: true,
                children: [
                    {
                        path: "Environment",
                        label: "환경설정",
                        element: <EnviromentPage/>,
                        visibleSideMenu: true,
                        children: []
                    },
                ]
            },

            {
                path: "MyPage",
                label: "마이페이지",
                visibleSideMenu: true,
                children: [
                    // {
                    //     path: "Alarm",
                    //     element: <AlarmListPage/>,
                    //     label: "내알림목록",
                    //     visibleSideMenu: true,
                    //     children: []
                    // },
                    {
                        path: "Message",
                        element: <MessageListPage/>,
                        label: "쪽지",
                        visibleSideMenu: true,
                        children: [
                            {
                                path: "Reception",
                                // element: <MessageListPage/>,
                                label: "수신목록",
                                visibleSideMenu: false,
                                children: [
                                    {
                                        path: ":id",
                                        element: null,
                                        label: "쪽지상세",
                                        visibleSideMenu: false,
                                    },
                                ]
                            },
                            {
                                path: "Outgoing",
                                // element: <MessageListPage/>,
                                label: "발송목록",
                                visibleSideMenu: false,
                            },
                            {
                                path: "Write",
                                element: null,
                                label: "쪽지보내기",
                                visibleSideMenu: false,
                            },
                        ]
                    },
                    {
                        path: "Info",
                        element: <MyInfoPage/>,
                        label: "내정보관리",
                        visibleSideMenu: true,
                        children: []
                    },
                ]
            },

            {
                path: "/Login",
                element: <LoginPage/>,
                label: "메인",
                visibleSideMenu: false,
            },

            {
                path: "/Print",
                element: <PrintPage/>,
                label: "메인",
                visibleSideMenu: false,
            },

        ]
    },



], {
    basename: process.env.PUBLIC_URL,

})

export { router }