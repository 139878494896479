import React from "react";
import {useQuery, useQueryClient} from "react-query";
import {queryKeys} from "../Constant/QueryKeys";
import {REPO} from "../Repository/repo";
import {UserInfoModel} from "../Model/UserModel";

const useUser = () => {
    const queryClient = useQueryClient();

    const userData = useQuery(queryKeys.userInfo, async ()=>{
        const result = await REPO.User.Info()
        return result.data
    },{
        refetchInterval: 1000 * 60 * 5,
    })


    function updateUser(newUser: UserInfoModel): void {
        queryClient.setQueryData(queryKeys.userInfo, newUser)
    }

    function clearUser() {
        queryClient.setQueryData(queryKeys.userInfo, null)
        // queryClient.removeQueries('user-appointments')
    }


    const userMessage = useQuery(queryKeys.userMessage, async ()=>{
        const result = await REPO.Message.GetMessageList("inbox", {
            offset: 0,
            limit: 1000,
        })
        return result.data
    },{

    })

    return {
        userData,
        updateUser,
        clearUser,
        userMessage
    }

}


export default useUser
