import React, {useEffect, useState} from 'react'
import {PageWrap} from "../../Components";
import MessageList from "../../Containers/MyPage/MessageList";
import {REPO} from "../../Repository/repo";
import {useQuery} from "../../Hooks/useQuery";
import {DEFAULT_LIST_COUNT} from "../../Constant/Common";
import {useLocation, useNavigate} from "react-router-dom";
import {MessageListModel, MessageRequestType} from "../../Model/MessageModel";
import {TablePaginationConfig} from "antd";
import {getMessageListType, queryAdd} from "../../Helper/Helper";
import useUser from "../../Hooks/useUser";

const MessageListPage = () =>{
    const navigate = useNavigate();
    const query = useQuery()
    const location = useLocation()
    const [total, setTotal] = useState(0)
    const [offset, setOffset] = useState(0)
    const [data, setData] = useState<MessageListModel[]>([])
    const [limit, setLimit] = useState(DEFAULT_LIST_COUNT)
    const pathname = location.pathname.split('/')[3]
    const type = getMessageListType(pathname) as MessageRequestType;
    const { userData } = useUser()

    const fetchMethod = async (type: string)=>{
        if(!type) return
        userData.refetch()
        try{
            const result = await REPO.Message.GetMessageList(type, {
                offset: Number(query.get("offset")),
                limit: Number(query.get("limit") || limit),
                search_query: query.get("search_query") || "",
                search_type: query.get("search_type") || "",
            })
            setData(result.data.list.map(info => ({...info, key: info.seq})))
            setTotal(result.data.total)
            setLimit(result.data.limit)
            setOffset(result.data.offset)
        }catch (e){
        }
    }

    const pageChangeHandler = (
        pagination: TablePaginationConfig,
    )=> {
        if(pagination.current && pagination.pageSize){
            query.set("offset", String((pagination.current-1) * pagination?.pageSize))
            query.set("limit", String(pagination.pageSize))
            const newQuery = queryAdd(query)
            navigate({
                pathname: location.pathname,
                search: newQuery
            })
        }
    }

    useEffect(()=>{
        fetchMethod(type)
    },[location, type])

    return(
        <PageWrap>
            {pathname === 'Reception' ? <h3>쪽지 수신 목록</h3> : <h3>쪽지 발송 목록</h3>}
            <MessageList
                data={data}
                isSendList={pathname !== 'Reception'}
                total={total}
                offset={offset}
                limit={limit}
                setLimit={setLimit}
                onChange={pageChangeHandler}
            />
        </PageWrap>
    )
}

export default MessageListPage;